import BlockContent from "@sanity/block-content-to-react";
import ContentBlockStyles from "../styles/ContentBlockStyles";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import PageHeadline from "../styles/PageHeadline";
import PropTypes from "prop-types";
import React from "react";
import SEO from "../components/SEO";

const HomePage = ({ data }) => {

  const { page, settings } = data;

  return (
    <>
      <SEO title={page.metaTitle || page.name} description={page.metaDescription} />
      <Img fluid={page?.image?.asset?.fluid || settings.image.asset.fluid} />
      <ContentBlockStyles className="homepage">
        <PageHeadline>{page.name}</PageHeadline>
        <BlockContent blocks={page._rawContent} />
      </ContentBlockStyles>
    </>
    
  );

};

const { any, bool, shape, string } = PropTypes;

HomePage.propTypes = {
  data: shape({
    page: shape({
      displayLogos: bool,
      image: shape({ asset: shape({ fluid: any }) }),
      metaDescription: string,
      metaTitle: string,
      name: string.isRequired,
      _rawContent: any.isRequired
    }).isRequired,
    settings: shape({ image: shape({ asset: shape({ fluid: any.isRequired }) }) }).isRequired
  }).isRequired
};


export default HomePage;

export const query = graphql`
  query {
    page: sanityHomePage(_id: {eq: "homePage"}) {
      name
      components {
        contactForm
        map
      }
      displayLogos
      image {
        asset {
          fluid(maxWidth: 800) {
            ...GatsbySanityImageFluid
          }
        }
      }
      metaDescription
      metaTitle
      _rawContent
    }
    settings: sanitySettings(_id: {eq: "SiteSettings"}) {
      image {
        asset {
          fluid(maxWidth: 800) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`;

